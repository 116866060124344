var navButton1 = document.getElementById("navButton1")
var navButton2 = document.getElementById("navButton2")
var navButton3 = document.getElementById("navButton3")
var navButton4 = document.getElementById("navButton4")
var navButton5 = document.getElementById("navButton5")

var section1 = document.getElementById("section1")
var section2 = document.getElementById("section2")
var section3 = document.getElementById("section3")
var section4 = document.getElementById("section4")
var section5 = document.getElementById("section5")

navButton1.onclick = onClickNavButton1
navButton2.onclick = onClickNavButton2
navButton3.onclick = onClickNavButton3
navButton4.onclick = onClickNavButton4
navButton5.onclick = onClickNavButton5

function onClickNavButton1() {
  section1.scrollIntoView({behavior: "smooth", block: "end", inline: "nearest"});
}

function onClickNavButton2() {
  section2.scrollIntoView({behavior: "smooth", block: "end", inline: "nearest"});
}

function onClickNavButton3() {
  section3.scrollIntoView({behavior: "smooth", block: "end", inline: "nearest"});
}

function onClickNavButton4() {
  section4.scrollIntoView({behavior: "smooth", block: "end", inline: "nearest"});
}

function onClickNavButton5() {
  section5.scrollIntoView({behavior: "smooth", block: "end", inline: "nearest"});
}

// LANGUAGE SWITCHING
const langSelectEN = document.getElementById("lang-select-en")
const langSelectJP = document.getElementById("lang-select-jp")

langSelectEN.onclick = function(){
  switchLanguage("en")
}

langSelectJP.onclick = function(){
  switchLanguage("jp")
}

function switchLanguage(lang) {
  if (lang == "en"){
    langSelectEN.style.opacity = 1
    langSelectJP.style.opacity = .5
    toggleLanguage("en", true)
    toggleLanguage("jp", false)
  } else if (lang == "jp"){
    langSelectEN.style.opacity = .5
    langSelectJP.style.opacity = 1
    toggleLanguage("en", false)
    toggleLanguage("jp", true)
  }
}

function toggleLanguage(lang, toggle) {
  const elements = document.querySelectorAll("." + lang)
  for (var i = 0; i < elements.length; i++){
    if (toggle == true){
      elements[i].style.display = "block"
    } else if (toggle == false){
      elements[i].style.display = "none"
    }
  }
}

switchLanguage("en")